import React, { useState, useEffect } from "react";
import Product from "./product";
import Difference from "./difference";
import ProductTranslation from "./product_translation";

import "../../packs/translations";
import axios from "axios";

const ProductTranslationSimilar = props => {
  const [productsList, setProductsList] = useState([]);
  const [differenceContent, setDifferenceContent] = useState(null);
  const [translationsList, setTranslationsList] = useState([]);
  const [isVisibleProducts, setIsVisibleProducts] = useState(false);
  const [isVisibleTranslations, setIsVisibleTranslations] = useState(false);

  useEffect(() => {
    axios
      .get(`/api/products/${props.product_id}/similar`, {
        params: {}
      })
      .then(response => {
        setProductsList(response.data);
      })
      .catch(error => {
        console.log(error.response);
      });
  }, []);

  const toggleVisibleProducts = e => {
    if (isVisibleProducts) {
      setIsVisibleProducts(false);
    } else {
      setIsVisibleProducts(true);
      setDifferenceContent(null);
      setTranslationsList([]);
    }
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-6">
          <Difference content={differenceContent} />
        </div>
        <div className="col-6">
          <a onClick={toggleVisibleProducts}>
            {isVisibleProducts
              ? I18n.t("js.hide_similar_products")
              : I18n.t("js.show_similar_products")}
          </a>
          <div
            className={
              isVisibleProducts
                ? "translations-products"
                : "hidden translations-products"
            }
          >
            {productsList.map(product => (
              <Product
                id={product.id}
                name={product.name_with_season}
                key={product.id}
                setIsVisibleProducts={setIsVisibleProducts}
                setIsVisibleTranslations={setIsVisibleTranslations}
                setTranslationsList={setTranslationsList}
              />
            ))}
          </div>
          <div
            className={
              isVisibleTranslations
                ? "translations-product-notes"
                : "hidden translations-product-notes"
            }
          >
            {translationsList.map(trans => (
              <ProductTranslation
                id={trans.id}
                product_note_id={props.product_note_id}
                content={trans.content}
                key={trans.id}
                ckeditor={props.ckeditor}
                setIsVisibleProducts={setIsVisibleProducts}
                setIsVisibleTranslations={setIsVisibleTranslations}
                setDifferenceContent={setDifferenceContent}
              />
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProductTranslationSimilar;
