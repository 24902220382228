import React, { useState } from 'react';
import axios from 'axios';

import '../../packs/translations';
I18n.locale = $('html').attr('lang');

export const BASIC_MAIL_VALIDATOR = /.+@.+\..+/;

const Newsletter = () => {
  const [email, setEmail] = useState('');
  const [response, setResponse] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    axios.post('/newsletters', {
      newsletter: { email: email }
    }).then(response => {
      setResponse(response.data.msg);
      setEmail('');
    }).catch(error => {
      console.log(error.response);
      setResponse(error.response.data.errors);
    })
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    const value = email;
    value.length > 0 && !BASIC_MAIL_VALIDATOR.test(value) ? setResponse(I18n.t("js.email_info")) : setResponse('');
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-3">
          <input
            type="email"
            name="newsletter[email]"
            id="newsletter_email"
            className="form-control"
            placeholder={I18n.t('js.newsletter.placeholder')}
            value={email}
            onChange={handleEmailChange}
          />
          {response ? <small className="text-secondary">{response}</small> : ''}
        </div>
        <div className="col-md-2">
          <input type="submit" name="commit" value={I18n.t('js.newsletter.submit')} className="btn btn-block btn-secondary" disabled={email && BASIC_MAIL_VALIDATOR.test(email) ? false : true} />
        </div>
      </div>
    </form>
  );
}

export default Newsletter;