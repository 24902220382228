import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ConfigurationOption from "./configuration_option";

import "../../packs/translations";

const Configuration = props => {
  const [isVisible, setIsVisible] = useState(false);
  const [rooms, setRooms] = useState(props.rooms);
  const [adults, setAdults] = useState(props.adults);
  const [children, setChildren] = useState(props.children);

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const handleRoomsChange = value => {
    setRooms(value);
    if (props.onRoomsChange) {
      props.onRoomsChange(value);
    }
  };

  const handleAdultsChange = value => {
    setAdults(value);
    if (props.onAdultsChange) {
      props.onAdultsChange(value);
    }
  };

  const handleChildrenChange = value => {
    setChildren(value);
    if (props.onChildrenChange) {
      props.onChildrenChange(value);
    }
  };

  return (
    <React.Fragment>
      <label>{I18n.t("js.configuration.label")}</label>
      <div className="btn-group btn-block">
        <button
          type="button"
          className="btn btn-white my-drop-btn filter-input form-control mt-0 fa-placeholder"
          onClick={handleClick}
        >
          <i className="fas fa-male" />
          {adults + children}
        </button>
        <div
          className={
            isVisible
              ? "dropdown-menu person-dropdown show"
              : "dropdown-menu person-dropdown"
          }
        >
          <div className="px-2 py-2">
            <ConfigurationOption
              name="rooms"
              value={props.rooms}
              label={I18n.t("js.configuration.rooms")}
              minValue={1}
              maxValue={2}
              changeCallback={value => handleRoomsChange(value)}
            />
            <ConfigurationOption
              name="adults"
              value={props.adults}
              label={I18n.t("js.configuration.adults")}
              minValue={1}
              maxValue={10}
              changeCallback={value => handleAdultsChange(value)}
            />
            <ConfigurationOption
              name="children"
              value={props.children}
              label={I18n.t("js.configuration.children")}
              minValue={0}
              maxValue={4}
              changeCallback={value => handleChildrenChange(value)}
            />
            <p className="small text-center">
              {I18n.t("js.configuration.children_age")}
            </p>
            <div className="text-center">
              <a
                className="btn btn-primary text-white pt-1 pb-1 pointer-cursor confirm-btn"
                onClick={handleClick}
              >
                {I18n.t("js.confirm")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

Configuration.propTypes = {
  adults: PropTypes.number.isRequired,
  children: PropTypes.number.isRequired,
  rooms: PropTypes.number.isRequired,
  onRoomsChange: PropTypes.func,
  onAdultsChange: PropTypes.func,
  onChildrenChange: PropTypes.func
};

Configuration.defaultProps = {
  adults: 2,
  children: 0,
  rooms: 1
};

export default Configuration;
