import React, {useEffect, useState} from 'react';
import axios from "axios";

const Product = (props) => {

  const openProduct = (e) => {
    axios.get(`/api/products/${props.id}/product_notes`, {
      params: {}
    }).then(response => {
      props.setTranslationsList(response.data);
      props.setIsVisibleProducts(false)
      props.setIsVisibleTranslations(true)
    }).catch(error => {
      console.log("chyba: "+error.response);
    });
  }

  return (
    <div id={props.id}>
      <a onClick={openProduct}>{props.name}</a>
    </div>
  )
}

export default Product;