import React from 'react';
import parse from 'html-react-parser';

const Difference = (props) => {

  return (
    <React.Fragment>
      {props.content !== null &&
        <div className="translation-card difference-card card mb-2">
          <div className="card-body p-2">
            {parse(props.content)}
          </div>
        </div>
      }
    </React.Fragment>
  )
}

export default Difference;