import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ConfigurationOption = (props) => {
  const [value, setValue] = useState(props.value);

  const addValue = (e) => {
    e.preventDefault();
    const newValue = value + 1;
    setValue(newValue);
    if(props.changeCallback) {
      props.changeCallback(newValue);
    }
  }

  const subValue = (e) => {
    e.preventDefault();
    const newValue = value - 1;
    setValue(newValue);
    if(props.changeCallback) {
      props.changeCallback(newValue);
    }
  }

  return (
    <div className="row mb-2 justify-content-center align-items-center">
      <input name={props.name} id={props.name} type="hidden" value={value} />
      <div className="col-4">
        <span>{props.label}</span>
      </div>
      <div className="col-3">
        <button className="btn btn-primary text-white btn-circle" onClick={subValue} disabled={value === props.minValue}>-</button>
      </div>
      <div className="col-2">
        <span>{value}</span>
      </div>
      <div className="col-3">
        <button className="btn btn-primary text-white btn-circle" onClick={addValue} disabled={value === props.maxValue}>+</button>
      </div>
    </div>
  )
}

ConfigurationOption.propTypes = {
  value: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  changeCallback: PropTypes.func
}

export default ConfigurationOption;