import React from 'react';
import parse from 'html-react-parser';
import axios from "axios";

const ProductTranslation = (props) => {

  const pasteContent = (e) => {
    props.setIsVisibleProducts(false)
    props.setIsVisibleTranslations(false)
    props.setDifferenceContent(null)
    CKEDITOR.instances[props.ckeditor].setData(props.content)
  }

  const getDifference = (e) => {
    axios.get(`/api/products/product_note_differences`, {
      params: {product_translation_id: props.id, product_note_id: props.product_note_id}
    }).then(response => {
      props.setDifferenceContent(response.data);
    }).catch(error => {
      console.log("chyba: "+error.response);
    });
  }

  return (
    <div className="translation-card card mb-2">
      <div className="card-body p-2">
        {parse(props.content)}
        <a className="btn btn-sm btn-warning mr-3" onClick={pasteContent}><i className="fa fa-arrow-up"></i> {I18n.t('js.apply_translation')}</a>
        <a className="btn btn-sm btn-outline-primary" onClick={getDifference}><i className="fa fa-arrow-left"></i> {I18n.t('js.differences')}</a>
      </div>
    </div>
  )
}

export default ProductTranslation;